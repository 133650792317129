import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from "src/redux/reducers/authReducer";
import { NavigationPath } from "src/types/navigation";
import jwt_decode from "jwt-decode";
import getUser from "src/rest/user/getUser";
import renew from "src/rest/auth/renew";

const getLocalstorageItem = (name: string): string => {
  return window?.localStorage?.getItem(name);
};

export const setLocalstorageItem = (name: string, value: string) => {
  window?.localStorage?.setItem(name, value);
};

export const clearLocalStorage = () => {
  window?.localStorage?.clear();
};

export const getTokenFromLocalStorage = (): string => {
  const token = getLocalstorageItem("token");
  try {
    const decoded = jwt_decode(token) as any;
    const exp = new Date(parseInt(decoded.exp) * 1000).toUTCString();

    if (exp < new Date().toUTCString()) {
      return "";
    }

    return token;
  } catch {
    return "";
  }
};

const useUser = () => {
  const { user, updateToken, updateUser, token } = useAuth();
  const router = useRouter();

  /**
   * Initialize
   */
  useEffect(() => {
    if (!user) {
      (async () => {
        try {
          // Redirect to login
          const token = getLocalstorageItem("token");
          if (token !== undefined && token !== "" && token !== null) {
            updateToken(token);
            const decoded = jwt_decode(token) as any;
            const exp = new Date(parseInt(decoded.exp) * 1000).toUTCString();

            if (exp < new Date().toUTCString()) {
              // token is expired so we need to login
              console.error("token expired");
              updateToken(undefined);
              updateUser(undefined);
              setLocalstorageItem("token", null);
              router.push({
                pathname: NavigationPath.LOGIN,
                query: {
                  next: router.asPath,
                },
              });
            }

            if (!decoded.userId) {
              throw new Error("No user id found!");
            }

            const user = await getUser({
              authToken: token,
              id: decoded.userId,
            });
            updateUser(user);
          } else {
            router.push({
              pathname: NavigationPath.LOGIN,
              query: {
                next: router.asPath,
              },
            });
          }
        } catch (error) {
          // Invalid or expired token, or something else went wrong.
          console.error("Token probably expired, or something else went wrong");
          setLocalstorageItem("token", null);
          updateToken(undefined);
          updateUser(undefined);
          router.push({
            pathname: NavigationPath.LOGIN,
            query: {
              next: router.asPath,
            },
          });
        }
      })();
    } else {
      // console.log("useUser user is defined");
      const token = getLocalstorageItem("token");
      const decoded = jwt_decode(token) as any;
      const exp = new Date(parseInt(decoded.exp) * 1000);

      const updateTime = new Date(exp.setHours(exp.getHours() - 1));

      if (updateTime < new Date()) {
        // console.log("should update token");
        renew({ token: token })
          .then((result) => {
            updateToken(result);
            setLocalstorageItem("token", result);
          })
          .catch(() => {
            // Couldn't refresh token, so sign out
            setLocalstorageItem("token", null);
            updateToken(undefined);
            updateUser(undefined);
            router.push({
              pathname: NavigationPath.LOGIN,
              query: {
                next: router.asPath,
              },
            });
          });
      }
    }
  }, [user, token, updateToken, updateUser, router]);

  return { user, token };
};

export default useUser;
