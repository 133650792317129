import React from "react";
import styled from "@emotion/styled";
import { AppBar, Toolbar, useTheme } from "@mui/material";

import Profile from "./components/Profile";
import Back from "./components/Back";
import { ThemeProps } from "theme";

const Container = styled(AppBar)`
  ${({ theme }: ThemeProps) => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing()};
    background: #222;
  `}
`;

const StyledToolbar = styled(Toolbar)`
  width: 100%;
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  text-align: center;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-top: 12px;
`;

interface HeaderProps {
  showBackButton?: boolean;
  backButtonOnClick?: () => void;
  style?: {};
}

/**
 * Header bar component.
 * @param backButtonOnClick You can override default back button on click handler. By default back button click will go previous route.
 */
const Header = ({ showBackButton, backButtonOnClick }: HeaderProps) => {
  const theme = useTheme();

  return (
    <Container theme={theme} position="fixed" elevation={0}>
      <LogoContainer>
        <LogoImage src="/assets/360-logo-vaaka.png" />
      </LogoContainer>
      <StyledToolbar disableGutters variant="dense">
        {showBackButton && <Back onClick={backButtonOnClick} theme={theme} />}
        <div style={{ margin: "auto" }} />
        <Profile />
      </StyledToolbar>
    </Container>
  );
};

export default Header;
