import React from 'react';
import styled from '@emotion/styled';
import SidePanel from '../SidePanel';

const AppContainer = styled.div`
  display:flex;
  flex-direction: column;
  height: 100vh;
`;

interface AppProps {
  appStyle?: React.CSSProperties;
}

const App = ({ children, appStyle = {} }: React.PropsWithChildren<AppProps>) =>
  <AppContainer style={appStyle}>
    <SidePanel>
      {children}
    </SidePanel>
  </AppContainer>;

export default App;