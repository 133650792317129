import config from "config";
import { AuthToken } from "src/types/auth";
import { UserBasicInfoDto } from "src/types/generated/userBasicInfoDto";

interface UserRequest extends AuthToken {
  /**
   * User id
   */
  id?: string;
}

/**
 * Get user
 */
const getUser = async ({ authToken, id }: UserRequest): Promise<UserBasicInfoDto> => {
  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${authToken}`,
  };

  const response = await fetch(
    `${config.backend.apiAddress}User/${id}`, {
    method: 'GET',
    cache: 'no-cache',
    headers,
  });

  if (!response.ok) {
    throw new Error("HTTP status " + response.status);
  }

  return response.json();
};

export default getUser;

