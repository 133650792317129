import React from 'react';
import { Container, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { ThemeProps } from 'theme';

const Content = styled(Container) <ThemeProps>`
  display: flex;
  flex: 1;
  flex-direction: column;

  /* Header bar height */
  margin-top: 64px;

  ${({ theme }: ThemeProps) => `
    padding-top: ${theme.spacing(2)};
  `}

  
`;

interface ContentContainerProps {
  style?: React.CSSProperties;
}

const ContentContainer = ({ children, style = {} }: React.PropsWithChildren<ContentContainerProps>) => {
  const theme = useTheme();

  return <Content theme={theme} style={style}>{children}</Content>;
};

export default ContentContainer;