import React, { useCallback } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Theme } from '@mui/material';
import { useRouter } from 'next/router';

interface BackProps {
  onClick?: () => void;
  theme: Theme;
}

const Back = ({ onClick, theme }: BackProps) => {
  const router = useRouter();

  /** 
   * Handle back button on click. If backButtonOnClick is set going to use that. By default go previous route.
   */
  const backButtonClickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      router.back();
    }
  }, [onClick, router]);

  return (
    <IconButton
      aria-label="back button"
      style={{ visibility: router?.pathname === '/' ? 'hidden' : 'visible' }}
      onClick={backButtonClickHandler}
      size="large">
      <ArrowBackIcon htmlColor={theme.palette.primary.contrastText} />
    </IconButton>
  );
};

export default Back;