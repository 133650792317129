import React, { useCallback } from "react";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ImageIcon from "@mui/icons-material/Image";
import styled from "@emotion/styled";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import Header from "../Header";
import { NavigationPath } from "src/types/navigation";
import { useRouter } from "next/router";
import { useAuth } from "src/redux/reducers/authReducer";

interface MenuItems {
  title: string;
  icon: JSX.Element;
  target: NavigationPath;
  "data-testid": string;
}

const Container = styled.div`
	display: flex;
	flex: 1;
`;

const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const LogoContainer = styled.div`
	display: flex;
	height: 63px;
	align-items: center;
	justify-content: center;
`;

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

const SidePanel = ({ children }: React.PropsWithChildren<any>) => {
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useAuth();

  const MenuItems = [
    {
      title: "Presentations",
      icon: <AccountTreeIcon />,
      target: NavigationPath.PRESENTATIONS,
      disabled: false,
      "data-testid": "presentations",
    },
    {
      title: "Users",
      icon: <PersonIcon />,
      target: NavigationPath.USERS,
      disabled: user.roleId != 2,
      "data-testid": "users",
    },
    /* {
      title: "Organizations",
      icon: <GroupIcon />,
      target: NavigationPath.ORGANIZATIONS,
      disabled: true,
      "data-testid": "organizations",
    }, */
    {
      title: "Materials",
      icon: <ImageIcon />,
      target: NavigationPath.MATERIALS,
      disabled: user.roleId != 2,
      "data-testid": "materials",
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const listItemClickHandler = (item: MenuItems) => () => {
    router.push(item.target);
  };

  const getListItems = useCallback(() => {
    // TODO: get logo container off on mobile view

    return (
      <>
        <LogoContainer>
          <img src="/assets/icons/360-corner-icon.png" height="40" />
        </LogoContainer>
        <Divider />
        <List component="nav">
          {MenuItems.map((item) => (
            <ListItem
              button
              data-testid={item["data-testid"]}
              key={item.target}
              onClick={listItemClickHandler(item)}
              selected={router?.asPath?.includes(item.target)}
              disabled={item.disabled}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {getListItems()}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {getListItems()}
          </Drawer>
        </Hidden>
      </nav>
      <Content>
        <Header />
        {children}
      </Content>
    </Container>
  );
};

export default SidePanel;
