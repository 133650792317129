import config from "config";

/**
 * Logout
 */
const logout = async (): Promise<number> => {
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const response = await fetch(
    `${config.backend.apiAddress}Auth/Logout`, {
    method: 'POST',
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers,
  });

  if (!response.ok) {
    throw new Error("HTTP status " + response.status);
  }

  return response.status;
};

export default logout;

