import config from "config";

interface RefreshRequest {
  token: string;
}

/**
 * Renew token
 */
const renew = async ({ token }: RefreshRequest): Promise<string> => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  };

  const response = await fetch(
    `${config.backend.apiAddress}Auth/Refresh`, {
    method: 'POST',
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers
  });

  if (!response.ok) {
    throw new Error("HTTP status " + response.status);
  }

  return response.json();
};

export default renew;

