import React, { useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { useRouter } from "next/router";
// import { NavigationPath } from "src/types/navigation";
import { useAuth } from "src/redux/reducers/authReducer";
import logout from "src/rest/auth/logout";
import { clearLocalStorage } from "src/hooks/user/useUser";
import { NavigationPath } from "src/types/navigation";
import router from "next/router";

const Container = styled.div``;

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, updateUser } = useAuth();
  const open = Boolean(anchorEl);
  // const router = useRouter();

  const menuOnCloseHandler = () => {
    setAnchorEl(null);
  };

  const menuOnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutOnClickHandler = async () => {
    try {
      await logout();
      clearLocalStorage();
      updateUser(undefined);
      router.push(NavigationPath.LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <IconButton
        data-testid="profile"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={menuOnClickHandler}
        color="inherit"
        size="large"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={menuOnCloseHandler}
      >
        {user?.userName && (
          <div
            style={{
              padding: "10px 16px 10px 16px",
              borderBottom: "1px solid #f2f2f2",
            }}
          >
            {user.userName}
          </div>
        )}
        {/* <MenuItem data-testid="profile-link">
					Profile
				</MenuItem> */}
        <MenuItem data-testid="logout" onClick={logoutOnClickHandler}>
          Logout
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Profile;
