import { Box, Typography } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React from 'react';

export function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress color="secondary" variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography style={{ color: "#e13d24" }} variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface LoaderProps {
  progress?: number;
  fullHeight?: boolean;
  preloaderProgress?: number;
  bgColor?: string;
}

const Loader = ({ progress, fullHeight = true, bgColor = "#000000" }: LoaderProps) => {
  return (
    <div style={{
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: bgColor,
      ...(fullHeight && { height: "100vh" }),
    }}>
      {progress !== undefined
        ? <CircularProgressWithLabel value={progress} />
        : <CircularProgress disableShrink color="secondary" />
      }

    </div>
  );
};

export default Loader;